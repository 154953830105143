<!-- 余额相关 -->
<template>
  <div>
      <!-- 搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 门店编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号:">
                    <a-input
                      v-model="queryParam.facilityCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 门店名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称:">
                    <a-input
                      v-model="queryParam.facilityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 档口编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="档口编号:">
                    <a-input
                      v-model="queryParam.kitchenCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 店铺名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="店铺名称:">
                    <a-input
                      v-model="queryParam.storeName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 客户签约主体 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="客户签约主体:">
                    <a-input
                      v-model="queryParam.licenseeEntityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24" class="fr">
                  <a-form-item>
                    <a-button type="primary" @click="loadTable()">
                        查询
                    </a-button>
                  </a-form-item>
                  <a-form-item>
                    <a-button @click="resetQueryParam">
                        重置
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <a-card style="margin-bottom: 24px">
        <div class="btnBox">
            <div style="display: flex; align-items: center;">
              <span class="btn-item"><a-button type="primary" @click="onExportCurrentBalance">当前余额导出</a-button></span>
              <span class="btn-item"><a-button type="primary" @click="onExportTransactionHistory">充值/扣费记录导出</a-button></span>
            </div>
        </div>
        <!-- table -->
        <a-spin :spinning="dataLoading">
            <a-table
                rowKey="contractId"
                :pagination="pagination"
                :columns="columns"
                :dataSource="tableData"
                :scroll="{ x: 800 }"
                @change="onTableChange"
            >
                <span slot="action" slot-scope="text, record" class="blueColor">
                <a
                    v-if="isCanViewBalanceDetail"
                    @click="viewDetail(record)"
                    style="margin-right:0.8em">
                    查看
                </a>
                </span>
            </a-table>
        </a-spin>
    </a-card>
    <a-modal
        title="当前余额导出"
        :visible="showExportCurrentBalance"
        :confirmLoading="showExportCurrentBalanceLoading"
        @ok="onConfirmExportCurrentBalance"
        @cancel="onCancelExportCurrentBalance"
        destroyOnClose
        >
        <a-row :gutter="30" style="line-height: 4">
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>城市：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.cityId"
                    placeholder="请选择"
                    @change="onChangeExportCity"
                >
                    <a-select-option v-for="item in userCitiesAll" :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="optional"> </span>
                <span>门店：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.facilityId"
                    allowClear
                    placeholder="请选择"
                >
                    <a-select-option v-for="item in exportAvailableFacility" :key="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>日期范围：</span>
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.startDate"
                    placehholder="选择日期"
                />
            </a-col>
        </a-row>
    </a-modal>
    <a-modal
        title="充值/扣费记录导出"
        :visible="showExportTransactionHistory"
        :confirmLoading="showExportTransactionHistoryLoading"
        @ok="onConfirmExportTransactionHistory"
        @cancel="onCancelExportTransactionHistory"
        destroyOnClose
        >
        <a-row :gutter="30" style="line-height: 4">
            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>选择城市：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.cityId"
                    placeholder="请选择"
                    @change="onChangeExportCity"
                >
                    <a-select-option v-for="item in userCitiesAll" :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="optional"> </span>
                <span>选择门店：</span>
                <a-select
                    style="width: 175px;margin-left: 0.5em;"
                    v-model="exportQuery.facilityId"
                    allowClear
                    placeholder="请选择"
                >
                    <a-select-option v-for="item in exportAvailableFacility" :key="item.id">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>

            <a-col :md="24" :sm="24">
                <span class="mandatory">*</span>
                <span>日期范围：</span>
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.startDate"
                    placehholder="选择开始日期"
                />
                <a-date-picker
                    class="date-picker"
                    format="YYYY-MM-DD"
                    v-model="exportQuery.endDate"
                    placehholder="选择结束日期"
                />
            </a-col>
        </a-row>
    </a-modal>
  </div>
</template>
<script>
  
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import { defaultPagination } from "../../../utils/pagination";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../../utils/auth";
import { FacilityActions } from "../../../utils/actions";
export default {
  name: "listBalance",
  props: {
    listBalanceEndpoint: String,
    exportBalanceEndpoint: String,
    exportTransactionEndpoint: String,
    balanceDetailRouteName: String,
    exportFilenamePrefix: String,
    viewDetailPermission: String,
  },
  data() {
    return {
      // 搜索
      queryParam: {
        facilityCode: "",
        facilityName: "",
        kitchenCode: "",
        storeName: "",
        licenseeEntityName: ""
      },
      // 分页
      pagination: defaultPagination(() => {}),
      // 表头
      columns: [
        {
          title: "城市",
          width: 100,
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "门店编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode"
        },
        {
          title: "门店名称",
          width: 160,
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "店铺名称",
          width: 180,
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "客户签约主体",
          width: 220,
          dataIndex: "licenseeEntityName",
          key: "licenseeEntityName",
        },
        {
          title: "当前余额",
          width: 220,
          dataIndex: "balance",
          key: "balance"
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          fixed: "right",
          scopedSlots: { customRender: "action" }
        }
      ],
      // 数据
      tableData: [],
      // dataLoading
      dataLoading: false,

      //导出通用相关
      exportAvailableFacility: [],
      exportQuery: {},

      //当前余额导出相关
      showExportCurrentBalance: false,
      showExportCurrentBalanceLoading: false,

      //充值/扣费记录导出相关
      showExportTransactionHistory: false,
      showExportTransactionHistoryLoading: false,

      current: 1,
      pageSize: 20,
    };
  },
  methods: {
    moment,
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.loadTable();
    },
    // 分页
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadTable();
    },
    loadTable() {
      this.tableData = [];
      
      this.dataLoading = true;
      axios({
        url: this.listBalanceEndpoint,
        method: "GET",
        params: {
          rows: this.pagination.pageSize || 20,
          page: this.pagination.current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.tableData = res.data.rows || [];
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
          this.dataLoading = false;
        } else {
          this.tableData = [];
          this.$message.error(`${res.data.returnMsg}`);
          const pagination = { ...this.pagination };
          pagination.total = null;
          pagination.current = 1;
          this.pagination = pagination;
          this.dataLoading = false;
        }
      });
    },
    // 初始化
    init() {
      this.pagination.current = 1;
      this.pagination.pageSize = 20;

      this.loadTable();
    },

    // 导出函数
    downloadTemplateWithPost(info, url, name) {
      this.$http.post(url, info,{
        responseType: "blob",
      })
      .then(res => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xlsx";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },

    // 跳转详情页面
    viewDetail(val) {
      this.$router.push({
        name: this.balanceDetailRouteName,
        params: {
          contractId: val.contractId,
          accountId: val.accountId,
          balanceAmount: val.balance,
        }
      });
    },

    onChangeExportCity() {
      if (this.exportQuery.cityId === undefined ||
          this.exportQuery.cityId === null) {
        this.exportAvailableFacility = [];
      }
      else {
        axios({
          url: this.$endpoints.FACILITY_LIST_FACILITY_BY_CITY_AND_BRAND,
          method: "GET",
          params: {
            cityIds: this.getCityIdList(this.exportQuery.cityId).join(","),
          }
        })
        .then(res => {
          if (res.data.success) {
            this.exportAvailableFacility = 
              res.data.rows.map(e => {
                return {
                  "id": e.id,
                  "name": e.optionText
                };
              });
          }
          else {
            this.$message.error("获取所选城市对应门店失败，错误信息：" + res.data.returnMsg, 3);
            this.exportAvailableFacility = [];
          }
        })
        .catch(error => {
          this.$message.error("获取所选城市对应门店出错，错误信息：" + error.message, 3);
          this.exportAvailableFacility = [];
        });
      }
    },

    // 当前余额导出相关
    onExportCurrentBalance() {
        this.showExportCurrentBalanceLoading = false;
        this.exportQuery = {};
        this.exportAvailableFacility = [];
        this.showExportCurrentBalance = true;
    },

    onConfirmExportCurrentBalance() {
        if (this.exportQuery.cityId === undefined || 
            this.exportQuery.cityId === null) {
            this.$message.error("请选择城市", 3);
            return;
        }
        if (this.exportQuery.startDate === undefined ||
            this.exportQuery.startDate === null) {
            this.$message.error("请选择起始日期", 3);
            return;
        }

        this.showExportCurrentBalanceLoading = true;

        const queryData = {
            'cityIdList': this.getCityIdList(this.exportQuery.cityId),
            'facilityIdList': this.exportQuery.facilityId ? [this.exportQuery.facilityId] : null,
            'startDate': this.exportQuery.startDate.format("YYYY-MM-DD"),
        }

        this.downloadTemplateWithPost(
          queryData,
          this.exportBalanceEndpoint,
          this.exportFilenamePrefix + "当前余额"
        );

        setTimeout(() => {
            this.showExportCurrentBalanceLoading = false;
            this.showExportCurrentBalance = false;
        }, 1000);
    },

    onCancelExportCurrentBalance() {
        this.showExportCurrentBalanceLoading = false;
        this.showExportCurrentBalance = false;
    },

    // 充值/扣费记录导出
    onExportTransactionHistory() {
        this.showExportTransactionHistoryLoading = false;
        this.exportQuery = {};
        this.exportAvailableFacility = [];
        this.showExportTransactionHistory = true;
    },

    onConfirmExportTransactionHistory() {
        if (this.exportQuery.cityId === undefined || 
            this.exportQuery.cityId === null) {
            this.$message.error("请选择城市", 3);
            return;
        }

        if (this.exportQuery.startDate === undefined ||
            this.exportQuery.startDate === null) {
            this.$message.error("请选择起始日期", 3);
            return;
        }

        if (this.exportQuery.endDate === undefined ||
            this.exportQuery.endDate === null) {
            this.$message.error("请选择结束日期", 3);
            return;
        }

        if (this.exportQuery.endDate < this.exportQuery.startDate) {
            this.$message.error("结束日期早于起始日期", 3);
            return;
        }

        this.showExportTransactionHistoryLoading = true;
        const queryData = {
            cityIdList: this.getCityIdList(this.exportQuery.cityId),
            facilityIdList: this.exportQuery.facilityId ? [this.exportQuery.facilityId] : null,
            startDate: this.exportQuery.startDate.format("YYYY-MM-DD"),
            endDate: this.exportQuery.endDate.format("YYYY-MM-DD"),
        }

        this.downloadTemplateWithPost(
          queryData,
          this.exportTransactionEndpoint,
          this.exportFilenamePrefix + "交易记录"
        );

        setTimeout(() => {
            this.showExportTransactionHistoryLoading = false;
            this.showExportTransactionHistory = false;
        }, 1000);
    },

    onCancelExportTransactionHistory() {
        this.showExportTransactionHistoryLoading = false;
        this.showExportTransactionHistory = false;
    },

    getCityIdList(cityId) {
      let cityIds = [];
      if (cityId == 0) {
        cityIds = this.userCities.map(e => e.id);
      }
      else {
        cityIds = [cityId];
      }

      return cityIds;
    }
  },
  activated() {
    if (this.$route.query.reset) {
      this.init();
    }
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("cities", ["userCities", "userCitiesAll"]),
    isCanViewBalanceDetail() {
      return hasPermissions(
        [this.viewDetailPermission],
        this.authenticatedUser.actions
      );
    }
  }
};
</script>
<style lang="less">
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ml15 {
  margin-left: 1.5em;
}
.btn-item {
  margin-right: 16px;
  margin-bottom: 10px;
}
.mandatory {
    display: inline-block;
    width: 15px;
    color: red;
    margin-right: 3px;
    text-align: right;
}
.optional {
    display: inline-block;
    width: 15px;
    margin-right: 3px;
    text-align: right;
}
.date-picker {
    margin-right: 15px;
}
</style>
